.mailBox-grid {
    background: #ffffff;

    .message-items {
        height: $mailBox-height;
        overflow: auto;

        .message-item-row {
            padding: 15px 10px;
            border-right: 1px solid #e8e8e8;
            position: relative;
            transition: .2s;
            cursor: pointer;

            &.active,
            &:hover {
                background: rgba(#e8e8e8, .3);
                transition: .2s;
            }

            .last-date {
                position: absolute;
                top: 5px;
                right: 15px;
                font-size: 13px;
                color: #cccccc;
            }

            .message-item {
                display: flex;
                align-items: center;
                width: 100%;

                .name {
                    padding-left: 15px;
                }
            }
        }
    }

    .message-viewer {
        .new-message {
            height: 61px;
            display: block;
            padding: 15px;
            border-top: 1px solid #e8e8e8;
            overflow: hidden;
            position: relative;

            .ant-input[disabled] {
                color: rgba(0, 0, 0, 0.65);
                background-color: #fff;
            }

            textarea {
                width: calc(100% - 50px);
                border: 0;
                outline: 0;
                box-shadow: none;
                resize:none
            }

            .button {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                width: 50px;
                height: 100%;
                right: 15px;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                transition: .1s;

                &.active {
                    visibility: visible;
                    opacity: 100%;
                    transition: .1s;
                }

                button {
                    background: #ffffff;
                    border: 0;
                    font-size: 1.5rem;
                    transform: rotate(90deg);
                    transition: .2s;
                    cursor: pointer;

                    &:not([disabled]):hover {
                        transform: rotate(0deg);
                        transition: .2s;
                    }
                }
            }
        }

        .massage-list {
            // padding: 15px 0 0;
            height: calc(#{$mailBox-height} - 61px);
            overflow: auto;
            display: block;
            position: relative;

            .ant-list-item {
                padding: 6px 0;

                .massage {
                    margin: 0 15px;
                    padding: 10px;
                    border: 1px solid #e8e8e8;
                    border-radius: $border-radius-base;
                    text-align: left;

                    &.mine {
                        background: rgba(#EBE4E2, .3);
                    }

                    .name {
                        font-size: 12px;
                        font-weight: bold;
                        margin-bottom: 0
                    }
                    .description {
                        margin-bottom: 0;
                        font-size: 15px;

                        img {
                            width: 200px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .spin {
        text-align: center;
        position: absolute;
        width: 100%;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        height: 100px;
        display: block;
        z-index: 9999;
        padding: 25px;
    }
}
