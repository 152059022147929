@import '~@microsoft/sp-office-ui-fabric-core/dist/sass/SPFabricCore.scss';
@import 'less.css';

@import "~rsuite/dist/styles/rsuite-default.css";

@import './scss/variables.scss';
@import "./scss/original/spacings.scss";
@import "./scss/original/original.scss";
@import "./scss/original/screens.scss";
@import "./scss/components/mailBox.scss";
@import "./scss/components/todo.scss";
@import "./scss/components/bigtable.scss";
@import "./scss/components/logisticsEditor.scss";
@import "./scss/core.scss";

.rs-picker-menu {
    z-index: 9999999;
}